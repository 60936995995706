import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WelcomeScreen from "./UiComponents/WelcomeScreen/WelcomeScreen";
import "./App.css";
import axiosClient from "./baseUrl/baseUrl";

const Home = () => {
  const navigate = useNavigate();
  const [welcomePageData, setWelcomePageData] = useState(null);
  const [variables, setVariables] = useState([]);

  useEffect(() => {
    const fetchWelcomePageData = async (campaignId, questionId) => {
      try {
        const response = await axiosClient.get(
          `campaign/${campaignId}/question/${questionId}`
        );
        console.log(response.data);
        setWelcomePageData(response?.data);
      } catch (error) {
        console.error("Error fetching welcome page data:", error);
      }
    };

    fetchWelcomePageData(1, 0);
    sessionStorage.clear();
  }, []);

  const handleStartButtonClick = async () => {
    if (welcomePageData?.next_question_id) {
      navigate(
        `campaign/${welcomePageData.campaign_id}/qns/${
          welcomePageData.next_question_id
        }?${generateRandomString()}`
      );
    }
  };

  const generateRandomString = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  return (
    <div className="App">
      {welcomePageData && (
        <WelcomeScreen
          title={welcomePageData.welcome_page_title}
          subTitle={welcomePageData.welcome_page_subtitle}
          mainImage={welcomePageData.welcome_page_image}
          customHtml={welcomePageData.welcome_page_html}
          showPrevButton={welcomePageData.show_previous_button}
          showNextButton={welcomePageData.show_next_button}
          showStartButton={welcomePageData.show_start_button}
          onClickStartButton={handleStartButtonClick}
          variables={variables}
          setVariables={setVariables}
        />
      )}
    </div>
  );
};

export default Home;

import React, { useEffect, useRef } from "react";
import Styles from "./MultiSelect.module.css";
import IonIcon from "@reacticons/ionicons";
import notificationSound from "../../assets/notification_option_select.mp3";

const MultiSelect = ({
  question,
  helpText,
  optionList,
  selectedOption,
  setSelectedOption,
  showPrevButton,
  onClickPrevButton,
  showNextButton,
  onClickNextButton,
}) => {
  const checkBoxRefs = useRef([]);

  useEffect(() => {
    checkBoxRefs.current.forEach((box, index) => {
      if (box) {
        if (selectedOption.find((option) => option.value === box.value)) {
          box.checked = true;
          box.parentNode.classList.add(Styles.gradient_border);
          box.parentNode.classList.add(Styles.vibrate);
        } else {
          box.checked = false;
          box.parentNode.classList.remove(Styles.gradient_border);
          box.parentNode.classList.remove(Styles.vibrate);
        }
      }
    });
  }, [selectedOption]);

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  const handleChange = (option) => {
    if (selectedOption?.find((opt) => opt.value === option.value)) {
      setSelectedOption(
        selectedOption?.filter((opt) => opt.value !== option.value)
      );
    } else {
      setSelectedOption([...selectedOption, option]);
      playNotificationSound();
    }
  };

  return (
    <div className={Styles.container}>
      <main className={Styles.main_content}>
        <div className={Styles.question_wrapper}>
          <h2 className={Styles.main_qns}>
            <IonIcon
              style={{ marginRight: "10px", verticalAlign: "baseline" }}
              name="arrow-forward-outline"
            />
            {question}
          </h2>
          {helpText && <p className={Styles.help_text}>{helpText}</p>}
        </div>
        <div className={Styles.checkbox_input_wrapper}>
          {optionList.map((option, index) => (
            <div key={option.id} className={Styles.checkbox_input}>
              <input
                ref={(el) => (checkBoxRefs.current[index] = el)}
                type="checkbox"
                id={`checkboxOption_${option.id}`}
                name="checkbox_option"
                value={option.value}
                onChange={() => handleChange(option)}
              />
              <label htmlFor={`checkboxOption_${option.id}`}>
                {option.option_name}
              </label>
            </div>
          ))}
        </div>
      </main>
      {selectedOption?.length === 0 ? (
        ""
      ) : (
        <footer className={Styles.footer_cta_wrapper}>
          {showPrevButton && (
            <button
              type="button"
              className={`${Styles.previous_btn} ${Styles.button}`}
              onClick={onClickPrevButton}
            >
              <span className={Styles.button__icon}>
                <IonIcon name="arrow-back-outline" />
              </span>
              <span className={Styles.button__text}>Previous</span>
            </button>
          )}
          {showNextButton && (
            <button
              type="button"
              className={`${Styles.next_btn} ${Styles.button}`}
              onClick={onClickNextButton}
            >
              <span className={Styles.button__text}>Next</span>
              <span className={Styles.button__icon}>
                <IonIcon name="arrow-forward-outline" />
              </span>
            </button>
          )}
        </footer>
      )}
    </div>
  );
};

export default MultiSelect;

// Loader.jsx
import React from "react";
import Styles from "./Loader.module.css";

const Loader = () => {
  return (
    <div className={Styles.loader_wrapper}>
      <div className={Styles.loading}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Loader;

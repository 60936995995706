import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QuestionRenderer from "./QuestionRenderer";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import axiosClient from "./baseUrl/baseUrl";
import Loader from "./utils/Loader/Loader";

const QuestionPage = () => {
  const { campaignId, questionId } = useParams();
  const navigate = useNavigate();
  const [questionData, setQuestionData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const uniqueId = uuidv4();
  useEffect(() => {
    sessionStorage.setItem("uniqueId", uniqueId);
  }, []);

  console.log("campaignId : ", campaignId);
  console.log("questionId : ", questionId);

  const fetchQuestionData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosClient.get(
        `campaign/${campaignId}/question/${questionId}`
      );
      console.log(response?.data);
      setQuestionData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching question data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestionData();
  }, [campaignId, questionId]);

  const handlePrevButtonClick = async () => {
    if (questionData?.previous_question_id) {
      navigate(
        `/campaign/${questionData.campaign_id}/qns/${
          questionData.previous_question_id
        }?${generateRandomString()}`
      );
    }
  };

  const handleNextButtonClick = async () => {
    console.log(questionData);
    const payload = {
      uuid: sessionStorage.getItem("uniqueId"),
      campaign_id: questionData?.campaign_id,
      company_id: questionData?.company_id,
      answers: [
        {
          question_id: questionData?.id,
          answer: questionData?.answer,
        },
      ],
    };

    console.log("Payload to send in POST call :");
    console.log(payload);

    try {
      const response = await axiosClient.post(`/answers`, payload);
      console.log(response?.data);
      if (response?.data) {
        if (questionData?.next_question_id !== null) {
          navigate(
            `/campaign/${questionData.campaign_id}/qns/${
              questionData.next_question_id
            }?${generateRandomString()}`
          );
        } else if (questionData?.answer?.next_question_id !== null) {
          navigate(
            `/campaign/${questionData.campaign_id}/qns/${
              questionData?.answer?.next_question_id
            }?${generateRandomString()}`
          );
        }
      }
    } catch (error) {
      console.error("Error fetching question data:", error);
    }
  };

  const handleSubmitButtonClick = () => {
    console.log("View Result Button Clicked");
    console.log(questionData);
  };

  const generateRandomString = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="App">
          <QuestionRenderer
            questionData={questionData}
            handlePrevButtonClick={handlePrevButtonClick}
            handleNextButtonClick={handleNextButtonClick}
            handleSubmitButtonClick={handleSubmitButtonClick}
            setQuestionData={setQuestionData}
          />
        </div>
      )}
    </>
  );
};

export default QuestionPage;

import React, { useEffect, useRef } from "react";
import Styles from "./TextInput.module.css";
import IonIcon from "@reacticons/ionicons";

const TextInput = ({
  question,
  helpText,
  inputLabelText,
  placeholderText,
  inputValue,
  setInputValue,
  showPrevButton,
  onClickPrevButton,
  showNextButton,
  onClickNextButton,
}) => {
  const textInputRef = useRef(null);
  const textInputWrapperRef = useRef(null);
  const clearBtnRef = useRef(null);
  const labelRef = useRef(null);

  useEffect(() => {
    const textInput = textInputRef.current;
    const textInputWrapper = textInputWrapperRef.current;
    const clearBtn = clearBtnRef.current;
    const label = labelRef.current;

    const handleFocus = () => {
      textInputWrapper.classList.add(Styles.vibrate, Styles.gradient_border);
    };

    const handleBlur = () => {
      if (textInput.value.trim() === "") {
        textInputWrapper.classList.remove(
          Styles.vibrate,
          Styles.gradient_border
        );
      }
    };

    const handleInput = () => {
      if (textInput.value.trim() !== "") {
        textInputWrapper.classList.add(Styles.vibrate, Styles.gradient_border);
        textInput.style.background = "#f2f2f2";
        label.classList.add(Styles.focused);
        clearBtn.classList.add(Styles.visible);
      } else {
        textInputWrapper.classList.remove(
          Styles.vibrate,
          Styles.gradient_border
        );
        textInput.style.background = "";
        label.classList.remove(Styles.focused);
        clearBtn.classList.remove(Styles.visible);
      }
    };

    const handleClear = () => {
      setInputValue("");
      textInputWrapper.classList.remove(Styles.vibrate, Styles.gradient_border);
      textInput.style.background = "";
      label.classList.remove(Styles.focused);
      clearBtn.classList.remove(Styles.visible);
    };

    const handlePlaceholderAnimation = (text, n) => {
      if (n < text.length) {
        textInput.setAttribute("placeholder", text.substring(0, n + 1));
        setTimeout(() => handlePlaceholderAnimation(text, n + 1), 100);
      }
    };

    const startPlaceholderAnimation = () => {
      handlePlaceholderAnimation(placeholderText, 0);
      setInterval(() => {
        handlePlaceholderAnimation(placeholderText, 0);
      }, 7000);
    };

    textInput.addEventListener("focus", handleFocus);
    textInput.addEventListener("blur", handleBlur);
    textInput.addEventListener("input", handleInput);
    clearBtn.addEventListener("click", handleClear);

    startPlaceholderAnimation();

    return () => {
      textInput.removeEventListener("focus", handleFocus);
      textInput.removeEventListener("blur", handleBlur);
      textInput.removeEventListener("input", handleInput);
      clearBtn.removeEventListener("click", handleClear);
    };
  }, [placeholderText, setInputValue]);

  return (
    <div className={Styles.container}>
      <main className={Styles.main_content}>
        <div className={Styles.question_wrapper}>
          <h2 className={Styles.main_qns}>
            <IonIcon
              style={{ marginRight: "10px", verticalAlign: "baseline" }}
              name="arrow-forward-outline"
            />
            {question}
          </h2>
          {helpText && <p className={Styles.help_text}>{helpText}</p>}
        </div>

        <div className={Styles.text_input_wrapper}>
          <div ref={textInputWrapperRef} className={Styles.text_input}>
            <input
              autoComplete="off"
              name="hidden"
              type="text"
              style={{ display: "none" }}
            />
            <input
              ref={textInputRef}
              autoComplete="off"
              type="text"
              id="textInput"
              name="textInput"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={placeholderText}
            />
            <label htmlFor="textInput">
              <p ref={labelRef} className={Styles.label_text}>
                {inputLabelText}
              </p>
            </label>
            <div ref={clearBtnRef} className={Styles.clear_btn}>
              X
            </div>
          </div>
        </div>
      </main>
      {inputValue === "" ? (
        ""
      ) : (
        <footer className={Styles.footer_cta_wrapper}>
          {showPrevButton && (
            <button
              type="button"
              className={`${Styles.previous_btn} ${Styles.button}`}
              onClick={onClickPrevButton}
            >
              <span className={Styles.button__icon}>
                <IonIcon name="arrow-back-outline" />
              </span>
              <span className={Styles.button__text}>Previous</span>
            </button>
          )}
          {showNextButton && (
            <button
              type="button"
              className={`${Styles.next_btn} ${Styles.button}`}
              onClick={onClickNextButton}
            >
              <span className={Styles.button__text}>Next</span>
              <span className={Styles.button__icon}>
                <IonIcon name="arrow-forward-outline" />
              </span>
            </button>
          )}
        </footer>
      )}
    </div>
  );
};

export default TextInput;

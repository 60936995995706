import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Styles from "./PhraseLoader.module.css";
import LoaderAnimation from "../../assets/animation.webp";

const PhraseLoader = ({ steps, duration }) => {
  const [stepCount, setStepCount] = useState(1);
  const numberOfSteps = steps.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setStepCount((prevCount) => prevCount + 1);
    }, duration);

    if (stepCount === numberOfSteps) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [stepCount, duration, numberOfSteps]);

  return (
    <div className={Styles.PhraseLoadingWrapper}>
      <div className={Styles.PhraseSectionWrapper}>
        <div className={Styles.LoaderImageWrapper}>
          <img
            src={LoaderAnimation}
            alt="Loader"
            className={Styles.LoaderImage}
          />
        </div>
        {steps.slice(0, stepCount).map((step, index) => (
          <div
            key={index}
            className={`${Styles.PhraseAnimation} ${
              index === 0 ? Styles["PhraseAnimation-first"] : ""
            } ${
              index === numberOfSteps - 1 ? Styles["PhraseAnimation-last"] : ""
            }`}
          >
            <h1 className={Styles.PhraseTypography}>{step.title}</h1>
          </div>
        ))}
      </div>
    </div>
  );
};

PhraseLoader.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  duration: PropTypes.number.isRequired,
};

export default PhraseLoader;

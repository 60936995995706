import React, { useState, useEffect, useRef } from "react";
import Styles from "./SingleSelect.module.css";
import IonIcon from "@reacticons/ionicons";
import notificationSound from "../../assets/notification_option_select.mp3";

const SingleSelect = ({
  question,
  helpText,
  optionList,
  selectedOption,
  setSelectedOption,
  showPrevButton,
  onClickPrevButton,
  showNextButton,
  onClickNextButton,
}) => {
  const radioRefs = useRef([]);

  useEffect(() => {
    radioRefs.current.forEach((radio) => {
      if (radio) {
        if (radio.value === selectedOption?.value) {
          radio.checked = true;
          radio.parentNode.classList.add(Styles.gradient_border);
          radio.parentNode.classList.add(Styles.vibrate);
        } else {
          radio.checked = false;
          radio.parentNode.classList.remove(Styles.gradient_border);
          radio.parentNode.classList.remove(Styles.vibrate);
        }
      }
    });
  }, [selectedOption]);

  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  const handleChange = (option) => {
    setSelectedOption(option);
    playNotificationSound();
  };

  return (
    <div className={Styles.container}>
      <main className={Styles.main_content}>
        <div className={Styles.question_wrapper}>
          <h2 className={Styles.main_qns}>
            <IonIcon
              style={{ marginRight: "10px", verticalAlign: "baseline" }}
              name="arrow-forward-outline"
            />
            {question}
          </h2>
          {helpText && <p className={Styles.help_text}>{helpText}</p>}
        </div>
        <div className={Styles.radio_input_wrapper}>
          {optionList.map((option, index) => (
            <div key={option.id} className={Styles.radio_input}>
              <input
                ref={(el) => (radioRefs.current[index] = el)}
                type="radio"
                id={`singleOption_${option.id}`}
                name="single_option"
                value={option.value}
                onChange={() => handleChange(option)}
              />
              <label htmlFor={`singleOption_${option.id}`}>
                {option.option_name}
              </label>
            </div>
          ))}
        </div>
      </main>
      {selectedOption === null ? (
        ""
      ) : (
        <footer className={Styles.footer_cta_wrapper}>
          {showPrevButton && (
            <button
              type="button"
              className={`${Styles.previous_btn} ${Styles.button}`}
              onClick={onClickPrevButton}
            >
              <span className={Styles.button__icon}>
                <IonIcon name="arrow-back-outline" />
              </span>
              <span className={Styles.button__text}>Previous</span>
            </button>
          )}
          {showNextButton && (
            <button
              type="button"
              className={`${Styles.next_btn} ${Styles.button}`}
              onClick={onClickNextButton}
            >
              <span className={Styles.button__text}>Next</span>
              <span className={Styles.button__icon}>
                <IonIcon name="arrow-forward-outline" />
              </span>
            </button>
          )}
        </footer>
      )}
    </div>
  );
};

export default SingleSelect;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import QuestionPage from "./QuestionPage";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="campaign/:campaignId/qns/:questionId"
          element={<QuestionPage />}
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;

import React from "react";
import SingleSelectWithImage from "./UiComponents/SingleSelectWithImage/SingleSelectWithImage";
import SingleSelect from "./UiComponents/SingleSelect/SingleSelect";
import MultiSelect from "./UiComponents/MultiSelect/MultiSelect";
import TextInput from "./UiComponents/TextInput/TextInput";
import VerificationScreen from "./UiComponents/VerificationScreen/VerificationScreen";

const QuestionRenderer = ({
  questionData,
  handlePrevButtonClick,
  handleNextButtonClick,
  handleSubmitButtonClick,
  setQuestionData,
}) => {
  if (!questionData) return null;

  switch (questionData.question_type) {
    case "SingleSelectWithImage":
      return (
        <SingleSelectWithImage
          question={questionData.question_text}
          helpText={questionData.help_text}
          optionList={questionData.question_attributes}
          selectedOption={questionData.answer}
          setSelectedOption={(option) =>
            setQuestionData({ ...questionData, answer: option })
          }
          showPrevButton={questionData.show_previous_button}
          onClickPrevButton={handlePrevButtonClick}
          showNextButton={questionData.show_next_button}
          onClickNextButton={handleNextButtonClick}
          imageShape="circular"
        />
      );
    case "SingleSelect":
      return (
        <SingleSelect
          question={questionData.question_text}
          helpText={questionData.help_text}
          optionList={questionData.question_attributes}
          selectedOption={questionData.answer}
          setSelectedOption={(option) =>
            setQuestionData({ ...questionData, answer: option })
          }
          showPrevButton={questionData.show_previous_button}
          onClickPrevButton={handlePrevButtonClick}
          showNextButton={questionData.show_next_button}
          onClickNextButton={handleNextButtonClick}
        />
      );
    case "MultiSelect":
      return (
        <MultiSelect
          question={questionData.question_text}
          helpText={questionData.help_text}
          optionList={questionData.question_attributes}
          selectedOption={questionData.answer || []}
          setSelectedOption={(options) =>
            setQuestionData({ ...questionData, answer: options })
          }
          showPrevButton={questionData.show_previous_button}
          onClickPrevButton={handlePrevButtonClick}
          showNextButton={questionData.show_next_button}
          onClickNextButton={handleNextButtonClick}
        />
      );
    case "TextInput":
      return (
        <TextInput
          question={questionData.question_text}
          helpText={questionData.help_text}
          inputLabelText={questionData.question_attributes.input_label_text}
          placeholderText={questionData.question_attributes.placeholder}
          inputValue={questionData.answer || ""}
          setInputValue={(value) =>
            setQuestionData({ ...questionData, answer: value })
          }
          showPrevButton={questionData.show_previous_button}
          onClickPrevButton={handlePrevButtonClick}
          showNextButton={questionData.show_next_button}
          onClickNextButton={handleNextButtonClick}
        />
      );
    case "VerificationScreen":
      return (
        <VerificationScreen
          name={questionData?.name}
          email={questionData?.email}
          mobileNumber={questionData?.mobile_number}
          onClickSubmitButton={handleSubmitButtonClick}
        />
      );
    default:
      return null;
  }
};

export default QuestionRenderer;

import React from "react";
import AppRoutes from "./Routes";
import "./App.css";

import PhraseLoader from "./utils/PhraseLoader/PhraseLoader";

const App = () => {
  return <AppRoutes />;
};

export default App;

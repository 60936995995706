import React, { useState, useEffect } from "react";
import { Input, Button, notification } from "antd";
import PhraseLoader from "../../utils/PhraseLoader/PhraseLoader";
import Styles from "./VerificationScreen.module.css";
import DataImage from "../../assets/data_image.jpg";

const steps = [
  { title: "Just sit tight...!" },
  { title: "Analyzing your inputs..." },
  { title: "Finding the best results, to match your requirements..." },
  { title: "We are almost done...!" },
  { title: "Great! We got something awesome for you." },
];

const VerificationScreen = () => {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [otpVerified, setOtpVerified] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoading(false), 5000);
  }, []);

  useEffect(() => {
    let timer;
    if (otpSent && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [otpSent, resendTimer]);

  const sendOtp = () => {
    if (mobile.length === 10) {
      // Simulate API call
      notification.success({
        message: "OTP sent successfully to your WhatsApp!",
      });
      setOtpSent(true);
      setResendTimer(60);
    } else {
      notification.error({
        message: "Please enter a valid 10 digit mobile number!",
      });
    }
  };

  const verifyOtp = () => {
    if (otp === "12345") {
      // Simulated OTP
      notification.success({ message: "OTP verified successfully!" });
      setOtpVerified(true);
    } else {
      notification.error({ message: "Invalid OTP, please try again!" });
    }
  };

  const handleSubmit = () => {
    // Simulate API call for submitting the details
    console.log({ name, mobile, email });
    notification.success({ message: "Details submitted successfully!" });
  };

  return (
    <div className={Styles.VerificationScreenWrapper}>
      {loading ? (
        <PhraseLoader steps={steps} duration={1500} />
      ) : (
        <>
          <div className={Styles.ImageWrapper}>
            <img src={DataImage} alt="data-image" />
          </div>
          <p className={Styles.heading_para}>
            To view results, please fillup the following details
          </p>
          <div className={Styles.FormWrapper}>
            <Input
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={Styles.InputField}
            />
            <Input
              placeholder="Mobile Number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className={Styles.InputField}
              maxLength={10}
            />
            <Button
              type="primary"
              onClick={sendOtp}
              disabled={otpSent && resendTimer > 0}
              className={Styles.Button}
            >
              {otpSent
                ? `Resend OTP on WhatsApp (${resendTimer})`
                : "Get OTP on WhatsApp"}
            </Button>
            {mobile.length === 10 && (
              <Input
                placeholder="OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className={Styles.InputField}
              />
            )}
            {mobile.length === 10 && (
              <Button
                type="primary"
                onClick={verifyOtp}
                className={Styles.Button}
                disabled={otpVerified}
              >
                Verify OTP
              </Button>
            )}
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={Styles.InputField}
            />
            {otpVerified && (
              <Button
                type="primary"
                danger
                onClick={handleSubmit}
                className={Styles.SubmitButton}
              >
                View Result
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default VerificationScreen;

import React from "react";
import Styles from "./WelcomeScreen.module.css";
import IonIcon from "@reacticons/ionicons";
import DOMPurify from "dompurify";

const WelcomeScreen = (props) => {
  const {
    title,
    subTitle,
    mainImage,
    customHtml,
    showPrevButton,
    onClickPrevButton,
    showNextButton,
    onClickNextButton,
    showStartButton,
    onClickStartButton,
    variables,
    setVariables,
  } = props;

  const sanitizedHtml = DOMPurify.sanitize(customHtml);

  return (
    <div className={Styles.container}>
      <h1>{title}</h1>
      <h3>{subTitle}</h3>
      <div className={Styles.image_wrapper}>
        <img
          src={mainImage}
          alt="welcome-page-image"
          style={{ width: "100%", height: "auto" }}
        />
      </div>
      <div
        className={Styles.custom_html_wrapper}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />

      <footer className={Styles.footer_cta_wrapper}>
        {showPrevButton && (
          <button
            type="button"
            className={`${Styles.previous_btn} ${Styles.button}`}
            onClick={onClickPrevButton}
          >
            <span className={Styles.button__icon}>
              <IonIcon name="arrow-back-outline" />
            </span>
            <span className={Styles.button__text}>Previous</span>
          </button>
        )}
        {showNextButton && (
          <button
            type="button"
            className={`${Styles.next_btn} ${Styles.button}`}
            onClick={onClickNextButton}
          >
            <span className={Styles.button__text}>Next</span>
            <span className={Styles.button__icon}>
              <IonIcon name="arrow-forward-outline" />
            </span>
          </button>
        )}

        {showStartButton && (
          <button
            type="button"
            className={`${Styles.next_btn} ${Styles.button}`}
            onClick={onClickStartButton}
          >
            <span className={Styles.button__text}>Let's Start</span>
            <span className={Styles.button__icon}>
              <IonIcon name="arrow-forward-outline" />
            </span>
          </button>
        )}
      </footer>
    </div>
  );
};

export default WelcomeScreen;
